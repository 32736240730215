import { Helmet } from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import {
  SplitContent,
  SplitFigure,
  Container,
  SplitSection,
  ReadMore,
  Text,
  Content,
} from '../components/styles'
import Arrow from '../components/Arrow'
import styled from 'styled-components'
import Hero from '../components/Hero'
import { AboutContact, VideoContainer } from '.'
import { FAQContainer, FAQs } from '../components/FAQs'
import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'

const Links = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 0.5rem;
  }
  a {
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    /* text-decoration: underline; */
  }
`

const Value = styled(Container).attrs({
  bg: '#fff',
  medium: true,
  shadow: true,
})`
  margin-bottom: 1.5rem;
  padding: 3rem;

  > div > div:first-child {
    padding-right: 3rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 639px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`

const StyledHero = styled(Hero)`
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0;
`
const Info = styled.div`
  z-index: 10;
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-top: 30vh;
  text-align: center;
  max-width: 600px;
  h3 {
    color: ${(props) => props.theme.colors.dark};
    text-shadow: none;
  }
`

const StyledVideoContainer = styled(VideoContainer)`
  background: #c12422;
  padding: 30px 0 60px 0;
  i,
  p {
    color: #fff !important;
  }
  i {
    font-weight: 600;
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    coast: {
      childImageSharp: {
        fluid: object
      }
    };
    ecology: {
      childImageSharp: {
        fluid: object
      }
    };
    top: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => (
  <Layout header="dark">
    <Helmet>
      <title>About Us - QLD Sports Aviators</title>
      <meta
        name="description"
        content="QSA represents the interests of our Affiliated Sports Aviation Clubs
        and their members with key Queensland state based stakeholders."
      />
    </Helmet>

    <Header>
      <h1>Queensland Sports Aviators (QSA)</h1>
    </Header>
    <FAQContainer>
      <FAQs>
        <h4 id="mission" style={{ color: '#c12422' }}>
          Our Mission
        </h4>
        <p>
          QSA represents the interests of all SAFA Affiliated Sports Aviation
          Clubs and their members with key Queensland state based stakeholders.
          Our enduring mission is to enable our pilots and passengers to safely
          enjoy exhilarating flight experiences, whilst providing a sustainable
          future for this to occur.
        </p>
        <h4 id="vision" style={{ color: '#c12422' }}>
          Our Vision
        </h4>
        <p>
          We seek to inspire adventure seeking Queenslanders to safely
          participate in our forms of flying and we seek to help build a
          sustainable sports aviation future for our affiliated clubs and their
          members.
        </p>
        <h4 id="#values" style={{ color: '#c12422' }}>
          Our Values
        </h4>
        <p>Core values which support our mission and vision are;</p>
        <ul>
          <li> Safety above all else - without safety there is no future.</li>
          <li>
            Inclusiveness - pilot or passenger, our forms of aviation are for
            all Queenslanders.
          </li>
          <li>
            Exhilarating adventure - participation in sports aviation delivers
            ever changing life experiences which are thrilling and
            overwhelmingly fulfilling.
          </li>
        </ul>
      </FAQs>
    </FAQContainer>

    <Container padY={true} style={{ paddingTop: 70 }} medium={true}>
      <SplitSection>
        <SplitFigure className="split-about">
          <Img
            // style={{ marginTop: 50 }}
            fluid={data.team.childImageSharp.fluid}
            alt="Just Patios team"
          />
          {/* <Img
            style={{ marginTop: 50 }}
            fluid={data.sewing.childImageSharp.fluid}
            alt="GFit Gavin"
          /> */}
        </SplitFigure>
        <SplitContent>
          <h1 style={{ fontSize: '2.3rem' }}>About Us</h1>
          <p>
            Queensland Sports Aviators (QSA) represents the interest of all SAFA
            affiliated sports aviation clubs from the NSW border to the Central
            Queensland city of Rockhampton.
          </p>

          <p>
            Those interests cover recreational &amp; competition flying, the
            need to accommodate our forms of aviation in infrastructure planning
            (land and airspace requirements), sports funding in its numerous
            forms, training and development of all pilots whether new or long
            standing and the promotion of our exhilarating forms of aviation
            throughout our area of operation.
          </p>

          <h4>Our activities include:</h4>
          <ul>
            <li>
              Representing our aviation interests with key State and Regional
              stakeholders
            </li>
            <li>Supporting safety programs for our pilots</li>
            <li>Supporting our clubs and their members</li>
            <li>
              Supporting our training schools throughout our area of operation
            </li>
            <li>
              Promoting our unique aviation forms to the broader Queensland
              public
            </li>
            <li>
              Encouraging Queenslanders to engage with our training schools &
              enjoy an exhilarating aviation experience
            </li>
            <li>
              Supporting the Sports Aviation Federation of Australia (SAFA) and
              its governance of our aviation forms
            </li>
            <li>Supporting the efforts of our clubs to grow our sport</li>
            <li>
              Distribution to clubs of Regional Development Levy funds,
              collected annually by SAFA and passed to QSA
            </li>
            <li>
              Working with organisations to secure funding that supports the
              growth of our sport
            </li>
            <li>
              Representing our interests in Queensland with aviation regulators
              and industry forums
            </li>
          </ul>
          <ReadMore to="/contact-us">
            Contact QSA
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
    </Container>

    <CTA
      inverted={true}
      title="Looking for your own flight experience?"
      subtitle="We’re here to help you."
      button="Contact Us"
      color="#272727"
      id="committee"
    />

    <Flex justifyContent="center" style={{ marginBottom: 30, marginTop: 70 }}>
      <h1>Committee</h1>
    </Flex>
    <Container
      style={{ borderRadius: 4 }}
      bg="#f2f2f2"
      padY={true}
      medium={true}
    >
      <SplitSection style={{ marginLeft: 0 }}>
        <SplitFigure>
          <Img fluid={data.sewing.childImageSharp.fluid} />
        </SplitFigure>
        <SplitContent>
          <h5>Meet the QLD Committee</h5>
          <p>
            <ul>
              <li>President - Paul Green</li>
              <li>Secretary - Paul Reilly</li>
              <li>Treasurer - Kent Gosden</li>
              <li>Committee Member - John Vasta</li>
              <li>Committee Member - Brett Paull</li>
              <li>Committee Member - Mike Zupanc</li>
              <li>Committee Member - Sonya Fardell</li>
              <li>Committee Member - Wayne Smith</li>
              <li>Committee Member - Terry Carroll</li>
            </ul>
          </p>

          <ReadMore to="contact-us">
            Contact the team
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
    </Container>
    <Box mt={6} id="resources" />
    <Content padX={true} padY={true}>
      <h2>Browse Pilot Resources</h2>

      <Links>
        <h3>Tools</h3>
        <li>
          <a target="_blank" href="http://bom.gov.au/australia/radar/">
            Bureau of Meterology
          </a>
        </li>
        <li>
          <a target="_blank" href="https://siteguide.org.au/">
            National Site Guide
          </a>
        </li>

        <li>
          <a target="_blank" href="http://ausrasp.com/">
            RASP
          </a>
        </li>

        <li>
          <a target="_blank" href="https://www.windy.com/">
            Windy
          </a>
        </li>

        <br />
        <h3>Other Associations</h3>
        <li>
          <a target="_blank" href="https://www.safa.asn.au/">
            Sports Aviation Federation of Australia - SAFA
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.nswhpa.org/">
            New South Wales - NSWHPA
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.vhpa.org.au/">
            Victoria - VHPA
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.thpa.org.au/">
            Tasmania - THPA
          </a>
        </li>
        <li>
          <a target="_blank" href="http://www.flywa.com.au/">
            Western Australia - FlyWA
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.acthpa.org/">
            Australian Capital Territory - ACTHPA
          </a>
        </li>
        <li>
          <a>South Australia - SAHPGA</a>
        </li>
        <li>
          <a>Northern Territory - NT</a>
        </li>
        <li>
          <a>Far North Queensland - FNQ</a>
        </li>
      </Links>
    </Content>
    <Box mt={6} />
  </Layout>
)

export const query = graphql`
  query AboutPageQuery {
    top: file(relativePath: { eq: "sports-break.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    meetSellars: file(relativePath: { eq: "gavin.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meetGavin: file(relativePath: { eq: "gavin2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    drSellars: file(relativePath: { eq: "about-gfit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    team: file(relativePath: { eq: "about-person.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sewing: file(relativePath: { eq: "committee.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
